body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('./fonts/open-sauce/OpenSauceSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('./fonts/open-sauce/OpenSauceSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('./fonts/open-sauce/OpenSauceSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('./fonts/open-sauce/OpenSauceSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
