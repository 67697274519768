.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .styled-form {
    display: flex;
    flex-direction: row; /* Change to row to align items horizontally */
    align-items: center;
    gap: 10px; /* Add space between elements */
  }
  
  .styled-form input,
  .styled-form button {
    height: 40px; /* Set a consistent height for both input and button */
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  }
  
  .styled-form input {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    min-width: 300px;
    /* max-width: 300px; Adjust width as needed */
    border: 1px solid; /* Match the toolbar's border width and style */
  }
  
  .styled-form button {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    box-sizing: border-box;
    /* font-weight: 500; */
    line-height: 1.75;
    --variant-textColor: hsl(210, 98%, 48%);
    --variant-outlinedColor: hsl(210, 98%, 48%);
    --variant-outlinedBorder: hsla(210, 98%, 48%, 0.5);
    --variant-containedColor: hsl(210, 100%, 95%);
    --variant-containedBg: hsl(210, 98%, 48%);
    font-size: 16px;
    text-transform: none;
    color: white;
    background-color: rgb(5, 7, 10);
    background-image: linear-gradient(rgb(51, 60, 77), rgb(11, 14, 20));
    box-shadow: rgb(71, 83, 107) 0px 1px 0px inset, rgb(0, 0, 0) 0px -1px 0px 1px inset;
    min-width: fit-content;
    outline: 0px;
    margin: 0px;
    text-decoration: none;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 8px;
    padding: 8px 12px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(51, 60, 77);
    border-image: initial;
  }
  
  .styled-form button:hover {
    background-color: #0056b3; /* Darker shade for hover */
  }

@media (max-width: 600px) { /* Adjust the breakpoint as needed */
  .styled-form input {
    width: 90%;
    min-width: unset; /* Remove the min-width constraint on small screens */
  }
}
